import * as React from "react";
import { ValidateResult } from "react-hook-form";
import { navigate } from "gatsby";

import { $count, $isemail, $length, $lengthin, $ok, $string, $valueornull, $valueorundefine } from "foundation-ts/commons";
import { $encodeBase64 } from "foundation-ts/data";
import { $ascii, $normspaces, $trim } from "foundation-ts/strings";
import { TSDate } from "foundation-ts/tsdate";
import { Ascending, Comparison, Descending, Nullable, Same } from "foundation-ts/types";
import { $inspect } from "foundation-ts/utils";
import { SessionDto } from "g1-commons/lib/doxecureClientTypes";
import { SessionStatus, SignaturePhase, SignatureStatus } from "g1-commons/lib/doxecureModelTypes";
import { SectionCategory, UserFlags, UserProfile } from "g1-commons/lib/doxecureTypes";

import { $g1Color } from "../@chakra-ui/gatsby-plugin/G1Style";

import { config } from "../config/config";
import { EmptyCard } from "../components/Cards/EmptyCard";
import { ErrorBox } from "../components/ErrorBox/ErrorBox";
import { SectionInfos } from "../containers/generics/SectionsList";
import { logout } from "../services/authentication.service";
import { componentMap, MandatoryEmail, MandatoryPassword, PasswordDownLimit, PasswordUpLimit } from "./TypesAndConstants";

export const $okd = $valueorundefine ;
export const $okn = $valueornull ;

export const optlog = (format: string, ...args: any[]) => {
    if (config?.logAll) {
        const n = $count(args) ;
        if (n) { console.log(format, $inspect(n === 1 ? args[0] :  args))}
        else { console.log(format) ; }
    }
} ;

export const okOrEmptyCard = (value:any, element:JSX.Element, isLoading?:boolean, isError?:boolean, errorMessage?:string, errorTitle?:Nullable<string>):JSX.Element => {
    return trueOrEmptyCard($ok(value), element, isLoading, isError, errorMessage, errorTitle) ;
} ;

export const okOrError = (value:any, element:JSX.Element, isLoading?:boolean, errorMessage?:string, errorTitle?:Nullable<string>):JSX.Element => {
    const isGood = $ok(value) ;
    return trueOrEmptyCard(isGood, element, isLoading, !isGood, errorMessage, errorTitle) ;
}

export const trueOrEmptyCard = (value:boolean, element:JSX.Element, isLoading:boolean = false, isError:boolean = false, errorMessage:string="Erreur de chargement de la fiche", errorTitle?:Nullable<string>, loadingMessage?:Nullable<string>):JSX.Element => {
    if (isError) {
        errorMessage = errorMessage.trim() ;
        errorMessage = errorMessage.length ?  _formatedMessage(errorMessage) : "Erreur de chargement." ;
        if (!$length(errorTitle)) { return (<span>{errorMessage}</span>) ; }
        return (<ErrorBox title={errorTitle!} message={errorMessage}></ErrorBox>)
    }
    if (isLoading) { return (<span>{$length(loadingMessage) ? _formatedMessage(loadingMessage!) : "Chargement en cours ..."}</span>) ; }
    return value ? element : (<EmptyCard />) ;
}

export const reverseComparison = (comp:Comparison):NonNullable<Comparison> => {
    return comp === undefined || comp === Same ? Same : (comp === Ascending ? Descending : Ascending) ;
}

function _formatedMessage(s:string):string { return s.endsWith('.') ? s : s+'.' }

export const validateEmail = (value:string, blacklist:string[] = []):ValidateResult => {
    const t = $trim(value) ;
    if (t.length) {
        if ($isemail(value)) {
            return !$count(blacklist) || !blacklist.includes(t) || `Vous ne pouvez pas utiliser le couriel ${t}` ;
        }
        return "Mauvais format de courriel" ;
    }
    return MandatoryEmail ;
} ;

export const validatePassword = (value:string|undefined):ValidateResult => {
    if ($lengthin(value, PasswordDownLimit, PasswordUpLimit)) {
        return $trim(value) === value || "Impossible de mettre des espaces en début ou en fin de votre mot de passe" ;
    }
    return MandatoryPassword ;
}

export const blobToBase64String = async (blob:Blob):Promise<string|null> => {
    return await _blobToBase64String(blob);
}

export const blobToURLData = async (blob:Blob):Promise<string|null> => {
    return await _blobToURLData(blob);
}
export const blobToArrayBuffer = async (blob:Blob):Promise<ArrayBuffer|null> => {
    return await _blobToArrayBuffer(blob);
}

export const bytesToDownloadURL = (bytes:Nullable<Uint8Array|ArrayBuffer>):string => {
    let ret = '' ;
    if ($length(bytes) > 0) {
        try {
            const downloadBlob = new Blob([bytes!]);
            const url = window.URL.createObjectURL(downloadBlob) ;
            if ($ok(url)) { ret = url}
        }
        catch(e) {
            console.log('bytesToDownloadURL() did catch an exception', e) ;
        }    
    }
    return ret ;
}

const _blobToURLData = (blob:Blob) => new Promise<string|null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result as (string|null));
    reader.onerror = error => reject(error);
});

const _blobToBase64String = (blob:Blob) => new Promise<string|null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onload = () => resolve($ok(reader.result) ? $encodeBase64(reader.result!) : null);
    reader.onerror = error => reject(error);
});

const _blobToArrayBuffer = (blob:Blob) => new Promise<ArrayBuffer|null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onload = () => resolve($ok(reader.result) ? reader.result as ArrayBuffer : null);
    reader.onerror = error => reject(error);
}) ;

export const trimOnWords = (source:Nullable<string>, len:number) => {
    const words = $normspaces(source).split(' ') ;
    const n = words.length ;
    let finalLength = 0 ;

    if (!n) { return '' ; }
    let ret = words[0] ;
    if (ret.length > len) { return ret.slice(0, len) ; }

    for (let i = 1 ; i < n ; i++) {
        const w = words[i] ;
        const l = w.length + 1 ;
        if (finalLength + l > len) { return ret ; } 
        ret += ' ' ;
        ret += w ;
        finalLength += l ;
    }
    return ret ;
}

export const canSessionBeSigned = (s:SessionDto, signerID:Nullable<string>):boolean|undefined =>
{
    if (!$ok(s.apid) || !$ok(s.currentPhase) || s.currentPhase === SignaturePhase.ServerTerminating || s.status !== SessionStatus.ToBeSigned || !$ok(signerID)) { 
        optlog('Refused case #1', s.apid, s.currentPhase, s.status, signerID)
        return false ; 
    }

    if (!$ok(s.signatures)) {
        // signatures array were not exported in SessionDto structure
        optlog('Refused case #2: no signatures array')
         return undefined ; 
    } 
    else {
        optlog('signatures:', s.signatures) ;
    }

    const n = s.signatures!.length ;
    if (n === 0) {
        optlog('Refused case #13: signatures array is empty') ;
        return false ;
    }

    let rank0WasSigned = false ;
    let rank1ServerCount = 0 ;
    let qscdStartCount = 0 ;
    let signedCount = 0 ;
    let signedQSCDStartCount = 0 ;
    let rank1SignedServerCount = 0 ;
    let toBeSignedIndex = -1 ;
    let sessionHasRankO = s.signatures![0].rank === 0 ;
    for (let i = 0 ; i < n ; i++) {
        const sgn = s.signatures![i] ;
        if (sgn.phase === SignaturePhase.QSCDStart) { 
            qscdStartCount++ ;
        } else if ((sgn.phase === SignaturePhase.Server) && (sgn.rank === 1)) {
            rank1ServerCount++ ;
        }

        if ($ok(sgn.signatureDate) && sgn.signatureStatus === SignatureStatus.Signed) {
            signedCount++ ;
            if (sgn.phase === SignaturePhase.QSCDStart) { signedQSCDStartCount ++ ; }
            if (sgn.rank === 0) { rank0WasSigned = true ; }
            if ((sgn.rank === 1) && (sgn.phase === SignaturePhase.Server)) { rank1SignedServerCount++ ; }
        }

        console.log(`${signerID} / ${sgn.signer.apid}`) ;
        if (sgn.signer.apid === signerID) { toBeSignedIndex = i }
    }

    if (toBeSignedIndex === -1) {
        optlog('Refused case #7: signature not found') ;
        return false ;
    }

    const signature = s.signatures![toBeSignedIndex] ;
    if (signature.signatureStatus === SignatureStatus.Refused) {
        optlog('Refused case #8: signer has already refused', signature.signatureDate, signature.signatureStatus) ;
        return false ;
    }
    if (signature.signatureStatus === SignatureStatus.Canceled) {
        optlog('Refused case #9: signer has already canceled the signature', signature.signatureDate, signature.signatureStatus) ;
        return false ;
    }
    if ($ok(signature.signatureDate) || signature.signatureStatus === SignatureStatus.Signed) {
        optlog('Refused case #4: signer has already signed', signature.signatureDate, signature.signatureStatus) ;
        return false ;
    }
    if (s.currentPhase !== signature.phase) { 
        optlog('Refused case #3: bad phase', s.currentPhase, signature.phase) ;
        return false ; 
    }
    if (signature.rank === 1 && sessionHasRankO && !rank0WasSigned) {
        optlog('Refused case #10: rank 1 signing with a rank 0 unsigned is not possible', signature.rank, sessionHasRankO, rank0WasSigned) ;
        return false ;
    }
    if (signature.rank === 2 && signedCount !== n - 1) {
        optlog('Refused case #11: rank 2 signing with previous signature remaining', signature.rank, signedCount, n) ;
        return false ;
    }
    if (s.currentPhase === SignaturePhase.Server) {
        if (signedQSCDStartCount < qscdStartCount) {
            optlog('Refused case #6: all QSCD start signature must be done before server signing', signedQSCDStartCount, qscdStartCount) ;
            return false ;
        }
        optlog('Accepted case #1: let connective decide which people can sign') ;
        return true ; // let the server decide which signature can be done for now
                      // TODO: fetch which signature has been really done by the server and make a better test here
    } else if (s.currentPhase === SignaturePhase.QSCDEnd) {
        if (rank1SignedServerCount < rank1ServerCount) {
            optlog('Refused case #12: all server signature must be done before qscd end signing', rank1SignedServerCount, rank1ServerCount) ;
            return false ;
        }
    }
 
    // if (signature.rank === 0 || // signature @ rank 0 is always possible
    //     (signature.rank === 1 && (rank0WasSigned || (rank1ServerCount === rank1SignedServerCount))) || // signature @ rank 1 is possible if signature @ rank 0 is done or all server signatures @ rank 1 are done
    //     (signature.rank === 2 && signedCount === n-1)) { // signature @ rank 2 is possible if allprevious signatures are done
        optlog('Accepted case #2') ;
        return true ; 
    // }

    // optlog('Refused case #5: No true option was found', signature.rank, toBeSignedIndex, signedCount, n, sessionHasRankO, rank0WasSigned) ;
    // return false ;    
}

export const forceLogout = async (route: string) => {
    await logout() ;
    navigate(route) ;
}

export const defaultSectionInformations = (category: SectionCategory): SectionInfos => {
    const componentType = componentMap.get(category) ;

    return {
        style: {
            color: $g1Color(`list.${componentType?.component}.write`),
            bg: $g1Color(`list.${componentType?.component}.bg`),
            rounded: "xl",
        },
        itemStyle: {
            borderTop: "1px",
            borderTopColor: $g1Color('list.spacer'),
            iconColor: $g1Color('list.icon'),
            hover: { bg: $g1Color(`list.${componentType?.component}.hover.bg`), color: $g1Color(`list.${componentType?.component}.hover.write`) },
            select: { bg: $g1Color(`list.${componentType?.component}.select.bg`), color: $g1Color(`list.${componentType?.component}.select.write`) }
        }
    } ;
}

export const isAdminUser = (userProfile?: UserProfile): boolean => {
    return userProfile === UserProfile.Administrator || userProfile === UserProfile.Root || userProfile === UserProfile.AdministratorCorporate ;
}

export const adminTitle = (userProfile?: UserProfile): string => {
    if ($ok(userProfile)) {
        return userProfile === UserProfile.Root ? "Super Administrateur" : (userProfile === UserProfile.Administrator ? "Administrateur" : "Gestionnaire") ;
    }
    return "" ;
}

export function fileNameString(s:Nullable<string>): string {
    s = $ascii($string(s)) ;
    return s.length === 0 ? s : s.replace(/[<>:\"\/\\\*\?\|]/g, "_") ;
} 

export const userRoleAndStatus = (userFlags?: Nullable<UserFlags>, isAdminCorporate?: boolean) => {
    if (!$ok(userFlags)) {
        return "" ;
    }

    let ret = "" ;
    switch(userFlags!.profile) {
        case UserProfile.User :
        case UserProfile.Administrator :
        case UserProfile.Root :
            ret = "Utilisateur" ;
            break ;
        case UserProfile.UserCorporate :
        case UserProfile.AdministratorCorporate :
            ret = isAdminCorporate ? "Utilisateur" : "Utilisateur d'organisation" ;
            break ;
        default:
            ret = "Contact" ;
            break ;
    }

    const date = $ok(userFlags!.date) ? TSDate.fromIsoString(userFlags!.date)?.toString("%d/%m/%Y") : "";
    if (userFlags?.profile === UserProfile.Contact) {
        ret += "" ;
    } else if (userFlags?.activated) {
        const adminComplement = isAdminUser(userFlags?.profile) ? _adminStatus(userFlags) : "" ;
        ret += (adminComplement !== "") ? ` depuis le ${date} - ${adminComplement}` : ` depuis le ${date}`;
    } else if (userFlags?.expired) {
        ret += ` (désactivé depuis le ${date})` ;
    } else  {
        ret += ` (en cours d'activation jusqu'au ${date})` ;
    }

    return ret ;    
}

const _adminStatus = (userFlags: UserFlags): string => {
    let ret = adminTitle(userFlags.profile) ;
    return $ok(userFlags.adminActivatedDate) ? `${ret} depuis le ${TSDate.fromIsoString(userFlags.adminActivatedDate)?.toString("%d/%m/%Y")}` : `${ret} (accès non activé)` ;
}