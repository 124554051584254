import { $tounsigned } from "foundation-ts/commons";
import { $trim } from "foundation-ts/strings";
import { $bool } from "foundation-ts/tsparser";
import { uint } from "foundation-ts/types";
import { BCASigningLevel } from "bca-client-signature";

import configJson from "../../static/config.json" ;
import { HashMethod } from "foundation-ts/crypto";

const title = $trim(process.env.DOXECURE_TITLE) ;
export const config = {
    app: {
        title: title.length > 0 ? title : "doxecure.com",
        resources: process.env.DOXECURE_RESOURCES_URL,
        qscd:$bool(process.env.DOXECURE_CHECK_FOR_QSCD),
        loginURL: process.env.DOXECURE_LOGIN_URL,
        adminLoginURL: process.env.DOXECURE_ADMIN_LOGIN_URL,
        cguURL: process.env.DOXECURE_CGU_URL,
        privacyURL: process.env.DOXECURE_PRIVACY_URL,
        version: configJson.version,
        templateManagement: $bool(process.env.DOXECURE_TEMPLATES_MANAGEMENT),
    },
    demo: false,
    with_external_providers: $bool(process.env.DOXECURE_WITH_EXTERNAL_PROVIDERS),
    logAll: $bool(process.env.DOXECURE_LOG),
    backend: {
        hashMethod: process.env.BACKEND_HASH_METHOD as HashMethod,
        checkRevocation: $bool(process.env.BACKEND_CHECK_REVOCATION),
        verifySignatures: $bool(process.env.BACKEND_VERIFY_SIGNATURES)
    },
    session: {
        maxNbDoc: $tounsigned(process.env.DOXECURE_MAX_DOCUMENTS),
        maxDocSize: $tounsigned(process.env.DOXECURE_MAX_DOCUMENT_SIZE),
        maxSessionSize: $tounsigned(process.env.DOXECURE_MAX_SESSION_SIZE)
    },
    passwordConstraints: {
        minSize:$tounsigned(process.env.DOXECURE_PASSWORD_MINLENGTH, 6 as uint),
        mustHaveLowercase:$bool(process.env.DOXECURE_PASSWORD_HAS_LOWERCASE),
        mustHaveUppercase:$bool(process.env.DOXECURE_PASSWORD_HAS_UPPERCASE),
        mustHaveDigit:$bool(process.env.DOXECURE_PASSWORD_HAS_DIGIT),
        mustHaveSpecial:$bool(process.env.DOXECURE_PASSWORD_HAS_SPECIAL),
    },
    otp: {
        length: $tounsigned(process.env.DOXECURE_OTP_LENGTH, 6 as uint),
        expiresIn: $tounsigned(process.env.DOXECURE_OTP_EXPIRES_IN, 1 as uint)
    },
    timeouts: {
        defaultSessionTimeout: $tounsigned(process.env.QSCD_BACKEND_DEFAULT_SESSION_TIMEOUT, 900 as uint),
        defaultTimeout: $tounsigned(process.env.QSCD_BACKEND_DEFAULT_TIMEOUT, 7000 as uint),
        loginTimeout: $tounsigned(process.env.QSCD_BACKEND_LOGIN_TIMEOUT, 120000 as uint),
        connectionTimeout: $tounsigned(process.env.QSCD_BACKEND_CONNECTION_TIMEOUT, 2000 as uint),
        waitingTokenLoopTimeout: $tounsigned(process.env.QSCD_BACKEND_WAITING_READERS_LOOP_TIMEOUT, 0 as uint),
    },
    redirectURI: process.env.DOXECURE_REDIRECT_URL,
    chambersign: {
        clientId: process.env.DOXECURE_CHAMBERSIGN_IN_CLIENT_ID,
        authenticationURL: process.env.DOXECURE_CHAMBERSIGN_IN_AUTH_URL,
        scopes:process.env.DOXECURE_CHAMBERSIGN_IN_SCOPES,
    },
    google: {
        clientId: process.env.DOXECURE_GOOGLE_CLIENT_ID,
        authenticationURL: process.env.DOXECURE_GOOGLE_AUTH_URL,
        scopes:process.env.DOXECURE_GOOGLE_SCOPES,
    },
};